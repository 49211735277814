import { FormsModule } from "@angular/forms";

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { HttpClientModule } from "@angular/common/http";
import { BlockUIModule } from "ng-block-ui";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToolbarComponent } from "./components/core/toolbar/toolbar.component";
import { MaterialModule } from "./material/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FooterComponent } from "./components/core/footer/footer.component";
import { FormModalComponent } from "./components/core/dialogs/form-modal/form-modal.component";
import { ConfirmModalComponent } from "./components/core/dialogs/confirm-modal/confirm-modal.component";

@NgModule({
   declarations: [
      AppComponent,
      ToolbarComponent,
      FooterComponent,
      FormModalComponent,
      ConfirmModalComponent,
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      BrowserAnimationsModule,
      MaterialModule,
      FlexLayoutModule,
      BlockUIModule.forRoot(),
      FormsModule,
   ],
   providers: [],
   bootstrap: [AppComponent],
   exports: [ToolbarComponent, FooterComponent],
})
export class AppModule {}
