import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import * as AOS from "aos";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "frontend";

  ngOnInit() {
    AOS.init(), window.addEventListener("load", AOS.refresh);
  }
}
