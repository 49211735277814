export const env = {
    "local": {
        "api_rest": "http://localhost:3000",
        "conektaKey": "key_LBqm3JdTn5XnvRxcP3BqATw",
        "sendEmail": "",
        "siteKey": "6LeVlUcjAAAAALcQ5MVmufAmYnfFEfH91troGCvY"
    },
    "development": {
        "api_rest": "https://api-dev.dongo.mx",
        "conektaKey": "key_LBqm3JdTn5XnvRxcP3BqATw",
        "sendEmail": "",
        "siteKey": "6LeVlUcjAAAAALcQ5MVmufAmYnfFEfH91troGCvY"
    },
    "production": {
        "api_rest": "https://api.dongo.mx",
        "conektaKey": "key_dzNdMgf1Xek3fqtwG7rtUKQ",
        "sendEmail": "",
        "siteKey": "6LeVlUcjAAAAALcQ5MVmufAmYnfFEfH91troGCvY"
    }
}