<!-- Contact  -->
<section class="form-container container-dongo" id="contacto">
   <form action="?" method="POST" id="formCaptcha" action="EnviarDatos.php">
      <div>
         <label for="">Nombre</label>
         <input
            type="text"
            placeholder="Nombre"
            name="userName"
            [(ngModel)]="userName"
            (change)="validName($event, 'validFirstName')"
         />
      </div>
      <div id="validFirstName" class="validInput" style="display: none">
         <p>Nombre inválido</p>
      </div>

      <div>
         <label for="">Correo</label>
         <input
            type="email"
            placeholder="ejemplo@gmail.com"
            name="email"
            [(ngModel)]="email"
            (change)="validEmail($event)"
         />
      </div>
      <div id="validEmail" class="validInput" style="display: none">
         <p>Correo inválido</p>
      </div>

      <div>
         <label for="">Teléfono</label>
         <input
            type="text"
            placeholder="55 5555 5555"
            name="phone"
            [(ngModel)]="phone"
            minlength="10"
            maxlength="10"
            (change)="validPhone($event)"
         />
      </div>
      <div id="validPhone" class="validInput" style="display: none">
         <p>Télefono inválido</p>
      </div>

      <div>
         <label for="">Mensaje</label>
         <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            name="text"
            [(ngModel)]="text"
         ></textarea>
      </div>

      <div>
         <label class="hid">Mensaje</label>

         <!-- Input Oculto del recaptcha-->
         <input
            type="hidden"
            id="g-recaptcha-response"
            name="g-recaptcha-response"
         />

         <button
            *ngIf="
               !userName ||
               !email ||
               !phone ||
               !text ||
               !isValidUserName ||
               !isValidEmail ||
               !isValidPhone
            "
            class="g-recaptcha btn-form btn-disabled"
         >
            Enviar
         </button>
         <button
            *ngIf="
               userName &&
               email &&
               phone &&
               text &&
               isValidUserName &&
               isValidEmail &&
               isValidPhone
            "
            class="g-recaptcha btn-form"
            (click)="sendForm()"
         >
            Enviar
         </button>
      </div>
   </form>
</section>
<!-- end of contact  -->
