import { env } from "./config_env";
export const api = env.production.api_rest;
export const properties = env.production;

export const lang = () => {
  let lang = window.navigator.language;
  lang = lang.toLowerCase();
  let language = lang.includes("en");
  if (language) {
    lang = "en";
  } else {
    lang = "es";
  }
  return lang;
};
