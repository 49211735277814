import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserI } from '../models/user';
import { JwtResponseI } from '../models/jwt-response';
import { tap } from 'rxjs/operators';
import {Observable, BehaviorSubject, from } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { api, lang } from '../../config/properties';

@Injectable()
export class AuthService {
  // AUTH_SERVER: string = 'http://localhost:3000';
  authSubject = new BehaviorSubject(false);
  private token: string;
  @BlockUI() blockUI: NgBlockUI;

  constructor(private httpClient: HttpClient) { }

  register(user: UserI): Observable<JwtResponseI> {
    return this.httpClient.post<JwtResponseI>(`${api}/auth/register`, 
    user).pipe(tap(
      (res: JwtResponseI) => {
        if(res) {
          //guardar token
          // this.saveToken(res.dataUser.accessToken, res.dataUser.expiresIn);
        }
      }
    ))
  }

  login(user: UserI): Observable<JwtResponseI> {
    return this.httpClient.post<JwtResponseI>(`${api}/auth/login`, 
    user).pipe(tap(
      (res: JwtResponseI) => {
        if(res) {
          //guardar token
          console.log('Variables globales', api);
          this.saveToken(res.dataUser.accessToken, res.dataUser.expiresIn);
        }
      }
    ))
  }

  logout(): void {
    this.token = '';
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem('EXPIRES_IN');
  }

  forgot(obj: Object): Observable<Object> {
    return this.httpClient.post<Object>(`${api}/auth/forgot`, 
    obj).pipe(tap(
      (res: Object) => {
        if(res) {
          //guardar token
          console.log('Respuesta', res);
        }
      }
    ))
  }

  reset(obj: Object): Observable<Object> {
    return this.httpClient.post<Object>(`${api}/auth/reset`, 
    obj).pipe(tap(
      (res: Object) => {
        if(res) {
          //guardar token
          console.log('Respuesta', res);
        }
      }
    ))
  }

  registerNewCustomer(data:any){
    this.blockUI.start();
    return this.httpClient.post<any>(`${api}/webAdmin/register?lang=${lang()}`, data).pipe(tap(
      (res: any) => {
        if(res) {
          this.blockUI.stop();
        }
      }, (error: any) => {
        this.blockUI.stop();
      }
    ))
  };

  sendEmail(emailData:any){
    this.blockUI.start();
    return this.httpClient.post<any>(`${api}/webAdmin/sendEmail?lang=${lang()}`, emailData).pipe(tap(
      (res: any) => {
        if(res) {
          this.blockUI.stop();
        }
      }, (error: any) => {
        this.blockUI.stop();
      }
    ))
  };

  private saveToken(token: string, expiresIn: string): void {
    localStorage.setItem("ACCESS_TOKEN", token);
    localStorage.setItem("EXPIRES_IN", expiresIn);
    this.token = token;
  }

  private getToken(): string{
    if(!this.token){
      this.token = localStorage.getItem("ACCESS_TOKEN");
    }
    return this.token;
  }

}
