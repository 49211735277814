<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="'/dongo'">
      <img src="../../../../assets/images/logo-navbar.svg" alt="" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="'/dongo'"
            >Inicio</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/dongo/plans'">Planes</a>
        </li>

        <!-- <li class="nav-item">
                    <a class="nav-link " [routerLink]="'/dongo/themes'">Temas</a>
                </li> -->

        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/dongo/faq'">FAQ</a>
        </li>
      </ul>
      <div class="d-flex flex-tool">
        <a
          href="http://dashboard.dongo.mx"
          target="_blank"
          rel="noopener noreferrer"
          class="linkToAdmin"
        >
          <button class="btn btn-navLight">Iniciar Sesión</button>
        </a>
        <button class="btn btn-navColor" [routerLink]="'/auth/register'">
          Registrarse
        </button>
      </div>
    </div>
  </div>
</nav>
