<footer class="foot">
   <!-- Footer Desktop  -->
   <div class="container-main foot-cont dis-noneFlexi">
      <div>
         <img src="../../../../assets/images/logo-dongo.svg" alt="" />
      </div>

      <div class="foot-div">
         <div>
            <h4>Compañia</h4>
            <ul>
               <li><a>Acerca de nosotros</a></li>
               <li>
                  <a
                     href="mailto:help@dongo.com.mx?&subject=Información acerca de Döngo.&body=Solicito más informacíon acerca de"
                     >Contáctanos</a
                  >
               </li>
               <li><a>Aviso de privacidad</a></li>
               <li><a>Términos y condiciones</a></li>
            </ul>
         </div>
         <div class="div-btn">
            <button class="btn-navColor" [routerLink]="'/auth/register'">
               Registrarse
            </button>
            <a
               href="http://dashboard.dongo.mx"
               target="_blank"
               rel="noopener noreferrer"
               class="linkToAdmin"
            >
               <button class="btn btn-navLight">Iniciar Sesión</button>
            </a>
         </div>
      </div>
   </div>
   <div class="hr-blue container-main dis-noneFlexi"></div>

   <div class="container-main foot-under dis-noneFlexi">
      <h4>© Döngo, 2021. Todos los derechos reservados.</h4>
      <div>
         <p>Síguenos en:</p>
         <div>
            <a href=""
               ><img src="../../../../assets/images/fb-foot.svg" alt="" />
            </a>
            <a href=""
               ><img src="../../../../assets/images/in-foot.svg" alt="" />
            </a>
            <a href=""
               ><img src="../../../../assets/images/ik-foot.svg" alt="" />
            </a>
         </div>
      </div>
   </div>
   <!-- End of footer desktop  -->
   <!-- Footer mobile  -->
   <div class="dis-DesNone">
      <div class="container-main">
         <div class="foot-cont foot-mob">
            <div>
               <h4>Compañia</h4>
               <ul>
                  <li><a href="">Acerca de nosotros</a></li>
                  <li>
                     <a
                        href="mailto:help@dongo.com.mx?&subject=Información acerca de Döngo.&body=Solicito más informacíon acerca de"
                        >Contáctanos</a
                     >
                  </li>
                  <li><a href="">Aviso de privacidad</a></li>
                  <li><a href="">Términos y condiciones</a></li>
               </ul>
            </div>

            <div class="btn-mobile">
               <button class="btn-navColor">Registrarse</button>
               <button class="btn btn-navLight">Iniciar Sesión</button>
            </div>
         </div>
         <div class="logos-mob">
            <h4>Síguenos en</h4>
            <div>
               <a href=""
                  ><img src="../../../../assets/images/fb-foot.svg" alt="" />
               </a>
               <a href=""
                  ><img src="../../../../assets/images/in-foot.svg" alt="" />
               </a>
               <a href=""
                  ><img src="../../../../assets/images/ik-foot.svg" alt="" />
               </a>
            </div>
         </div>
         <div class="hr-blue"></div>
         <img src="../../../../assets/images/logo-dongo.svg" alt="" />
         <p>© Döngo, 2021. Todos los derechos reservados.</p>
      </div>
   </div>
   <!-- End of footer mobile -->
</footer>
