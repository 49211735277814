<ng-template #blockTemplate>
  <div class="block-ui-template w-100">
  </div>
</ng-template>
<block-ui [template]="blockTemplate">

<block-ui>
  <!-- <app-toolbar></app-toolbar> -->
  <router-outlet></router-outlet>
  <!-- <app-footer></app-footer> -->
</block-ui>
