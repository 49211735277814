import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class GlobalServiceService {
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(message: any) {
    this._snackBar.open(message, "", {
      duration: 9000,
      horizontalPosition: "start",
      verticalPosition: "bottom",
    });
  }
}
