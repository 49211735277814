import { Component, OnInit } from "@angular/core";
import { GlobalServiceService } from "src/app/services/global-service.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AuthService } from "src/app/services/auth.service";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Component({
   selector: "app-form-modal",
   templateUrl: "./form-modal.component.html",
   styleUrls: ["./form-modal.component.scss"],
})
export class FormModalComponent implements OnInit {
   @BlockUI() blockUI!: NgBlockUI;
   public userName: string;
   public email: string;
   public phone: string;
   public text: string;

   public isValidUserName: boolean = false;
   public isValidEmail: boolean = false;
   public isValidPhone: boolean = false;
   constructor(
      private globalService: GlobalServiceService,
      private authService: AuthService,
      private recaptchaV3Service: ReCaptchaV3Service
   ) {}

   ngOnInit(): void {}

   public executeImportantAction(emailObj): void {
      this.recaptchaV3Service.execute("importantAction").subscribe((token) => {
         console.log("Datos del mensaje", emailObj);
         console.log("Token", token);
         emailObj.captchaToken = token;
         this.sendEmail(emailObj);
      });
   }

   sendForm() {
      //Validacion del captcha
      console.log(
         "Formulario",
         this.userName,
         this.email,
         this.phone,
         this.text
      );

      let emailObj: any = {
         userName: this.userName,
         email: this.email,
         phone: this.phone,
         text: this.text,
         captchaToken: "",
      };
      this.executeImportantAction(emailObj);
   }

   sendEmail(emailObj) {
      console.log("Info de email", emailObj);
      this.authService.sendEmail(emailObj).subscribe(
         (res) => {
            this.userName = "";
            this.email = "";
            this.phone = "";
            this.text = "";
            this.globalService.openSnackBar("Se envio el corre electronico");
         },
         (error) => {
            console.log("error", error);
            this.globalService.openSnackBar(
               "Ocurrió un error al enviar la información, por favor intente más tarde"
            );
            this.blockUI.stop();
         }
      );
   }

   // Validaciones

   validName(event, div) {
      let isValid = event.srcElement.value.match(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*$/);
      this.isValidUserName = isValid;
      this.showInputError(div, isValid);
   }

   validEmail(event) {
      let isValid = event.srcElement.value.match(/^[^@]+@[^@]+.[a-zA-Z]{2,}$/);
      this.isValidEmail = isValid;
      this.showInputError("validEmail", isValid);
   }

   validPhone(event) {
      let isValid =
         event.srcElement.value.match(/^([0-9])*$/) &&
         event.srcElement.value.length == 10;
      this.isValidPhone = isValid;
      this.showInputError("validPhone", isValid);
   }

   showInputError(div, isValid) {
      if (!isValid) {
         document.getElementById(div).style.display = "block";
      } else {
         document.getElementById(div).style.display = "none";
      }
   }
}
